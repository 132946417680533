import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Error from './Error';
import Sync from '../../icons/Sync';
import ErrorRequired from './ErrorRequired';

import { breakpoints } from '../../../utils/breakpoints';
import { formInputFocus } from '../../../utils/shared/focusStyles';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.375rem;

  @media ${breakpoints.tablet} {
    width: 60%;
    margin: 3.125rem auto 0 auto;
  }

  @media ${breakpoints.desktop} {
    width: 50%;
  }
`;

const InputLabel = styled.label`
  margin-bottom: 0.5rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  line-height: 120%;
  letter-spacing: 0.015em;

  svg {
    margin-bottom: 2px;
    margin-left: 7px;
  }
`;

const Input = styled.input`
  padding: 0.875rem;
  border: 1px solid #bbbbbb;
  border-radius: 2px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;

  &::placeholder {
    font-style: italic;
    color: #6c6c6c;
  }

  &:disabled {
    background-color: #eeeeee;
    cursor: not-allowed;

    &::placeholder {
      color: #555555;
    }
  }

  ${formInputFocus};

  ${props => {
    if (props.hasError) {
      return `
        border-color: #8b1313;
      `;
    }
  }};
`;

export default function TextInput({ value, id, label, placeholder, handleOnChange, disabled, required, error }) {
  return (
    <InputContainer>
      <InputLabel htmlFor={id}>
        {label}
        {required && <ErrorRequired />}
        {disabled && <Sync />}
      </InputLabel>
      <Input
        id={id}
        value={value}
        name={id}
        autoComplete='off'
        placeholder={placeholder}
        onChange={handleOnChange}
        hasError={!!error}
        disabled={disabled}
        required={required}
        // aria-invalid={'true'}
      />
      {error && <Error message={error} />}
    </InputContainer>
  );
}

TextInput.propTypes = {
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

TextInput.defaultProps = {
  error: '',
  disabled: false,
  required: false,
  placeholder: '',
};
