import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FilterSearchInput from '../inputs/FilterSearchInput';
import ProgramFilterDropdown from '../ProgramFilterDropdown';
import FilterCheckboxGrid from '../inputs/FilterCheckboxGrid.styled';
import ProfileCheckboxInput from '../../../base/inputs/ProfileCheckboxInput';

import useSubjectAreasOptions from '../../../../hooks/useSubjectAreasOptions';
import { toggleProgramsFilter } from '../../../../actions/programDashboardActions';

const DropdownContainer = styled.div`
  margin: 0 -1.0625rem;
  padding-top: 1rem;
`;

export default function DesktopSubjectAreasFilter({ handleClose }) {
  const dispatch = useDispatch();
  const { selectedFilters } = useSelector(state => state.programDashboard);

  const [allOptions, subjectAreas, setSubjectAreas] = useSubjectAreasOptions();

  const [value, setValue] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (selectedFilters.subjectAreas.length > 0) {
      setValue(selectedFilters.subjectAreas);
    }
  }, []);

  const handleSearch = debounce(term => {
    if (term) {
      setSubjectAreas(allOptions.filter(subject => subject.label.toLowerCase().includes(term)));
    } else {
      setSubjectAreas(allOptions);
    }
  }, 500);

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
    let searchKey = event.target.value;
    handleSearch(searchKey.toLowerCase());
  };

  const handleOnChange = event => {
    const subjectArea = event.target.value;

    if (value.includes(subjectArea)) {
      setValue(value.filter(element => element !== subjectArea));
    } else {
      setValue([...value, subjectArea]);
    }
  };

  const handleClearAll = () => {
    setValue([]);

    dispatch(toggleProgramsFilter({ name: 'subjectAreas', value: [] }));
  };

  const handleFilter = () => {
    dispatch(toggleProgramsFilter({ name: 'subjectAreas', value }));

    handleClose();
  };

  return (
    <ProgramFilterDropdown handleFilter={handleFilter} handleClearAll={handleClearAll} handleClose={handleClose}  readerLabel={'Subject Areas filter dialogue box'}>
      <DropdownContainer>
        <FilterSearchInput
          id="subject_areas_search"
          value={searchTerm}
          label="Search Subject Areas"
          placeholder="Search Subject Areas"
          handleOnChange={handleSearchChange}
        />
        <FilterCheckboxGrid>
          {subjectAreas.map(subjectArea => (
            <ProfileCheckboxInput
              key={`subject-area-${subjectArea.value.split(' ').join('_')}`}
              id={`subject_area_${subjectArea.value.split(' ').join('_')}`}
              value={subjectArea.value}
              label={subjectArea.label}
              checked={value.includes(subjectArea.value)}
              handleOnChange={handleOnChange}
            />
          ))}
        </FilterCheckboxGrid>
      </DropdownContainer>
    </ProgramFilterDropdown>
  );
}

DesktopSubjectAreasFilter.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
