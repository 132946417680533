import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FocusTrap from 'focus-trap-react';
import { useSelector } from 'react-redux';

import { breakpoints } from '../../utils/breakpoints';

import { ViaInternationalIcon, ViaGlobalIcon, ViaInternationalInactiveIcon } from '../../../../styledComponents/styles/IconCustom.styled';
const Container = styled.nav`
  position: absolute;
  top: 20%;
  left: 2%;
  z-index: 120;
  min-width: 300px;
  
  display: flex;
  width: 529px;
  // height: 188px;
  padding: 28px 0px;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  flex-shrink: 0;
  border-radius: 15px;
  background: var(--white, #FFF);
  box-shadow: 0px 4px 20px 12px rgba(0, 0, 0, 0.16);

  @media ${breakpoints.tablet} {
    top: 12%;
    right: 0;
    border-radius: 15px;
  }
`;

const Content = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  gap:30px;
  width:529px;
`;

const IconContainerActive = styled.div`
    display: flex;
    width: 50%;
    min-height: 188px;
    padding: 19.437px;
    align-items: flex-start;
    gap: 6.248px;
    border-radius: 10.413px;
    background: var(--white, #FFF);
    box-shadow: 0px 2.777px 10.413px 0px rgba(0, 0, 0, 0.16) inset;
`;
const IconContainer = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px;
    
    display:flex;
    flex-direction:column;
    align-items:center;
    width:50%;
    &:hover {
      background: #F2F5F8;
    }
`;
const Description = styled.div`
  color: var(--gray-medium3, #666);
  leading-trim: both;
  text-edge: cap;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  letter-spacing: 0.035px;
`;

const IconLabel = styled.div`
  color: var(--gray-dark, #474747);
  text-align: center;

  /* Headline 6 */
  font-family: Nunito;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 25.2px */
  letter-spacing: 0.032px;
  margin-top:10px;
  display:flex;
`;

const LinkContainer = styled.a`
  text-decoration : none;
  // text-align:center;
`;
const LinkContainerInactive = styled.div`

`;

export default function ViaMenuDropdown({ isOpen, menuButtonRef, onClickOutside, inboundOrgData }) {
  const ref = useRef(null);
  const { currentUser } = useSelector(state => state.profile);
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        menuButtonRef.current &&
        !menuButtonRef.current.contains(event.target)
      ) {
        onClickOutside && onClickOutside();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

    const currentUrl = window.location.href;
    // Regular expression to extract subdomain and domain
    const subdomainRegex = /\/\/([^/]+)\//;
    // Extract the subdomain and domain
    const match = currentUrl.match(subdomainRegex);
    const subdomain = match ? match[1] : null;
    // Split the domain into second-level domain and top-level domain
    const domainParts = subdomain ? subdomain.split('.') : [];
    const secondLevelDomain = domainParts.length >= 2 ? domainParts[0] : null;
    const topLevelDomain = domainParts.length >= 2 ? domainParts.slice(1).join('.') : null;

  const internationalProduct = inboundOrgData?.organizationProducts?.find(item=>item.name=="INBOUND");
  
  return (
    <>   
      <Container ref={ref}>
        <Content>
          <IconContainerActive>
            <LinkContainer href={`https://${secondLevelDomain}.${topLevelDomain}`} target="_blank" style={{textDecoration:"none"}}>
              <ViaGlobalIcon width="60" height="60" />
              <IconLabel>Via Global</IconLabel>
            </LinkContainer>
          </IconContainerActive>
          <IconContainer>
          { currentUser?.via_international ? 
            <LinkContainer href={`https://${secondLevelDomain}.inbound.${topLevelDomain}`} target="_blank" style={{textDecoration:"none"}}>
                <ViaInternationalIcon width="60" height="60" />
              <IconLabel>{internationalProduct?.label ? internationalProduct?.label : "Via International" }</IconLabel>
              <Description>{internationalProduct?.description ? internationalProduct?.description : "" }</Description>
            </LinkContainer>
            :
            <LinkContainerInactive >
              <ViaInternationalInactiveIcon width="60" height="60" />
              <IconLabel style={{color: "var(--gray-medium2, #999)", textDecoration:"none"}}>{internationalProduct?.label ? internationalProduct?.label : "Via International" }</IconLabel>
              <Description style={{color: "var(--gray-medium2, #999)"}}>{internationalProduct?.description ? internationalProduct?.description : "" }</Description>
            </LinkContainerInactive>
          }
          </IconContainer>
        </Content>
      </Container>
    </>
  );
}

ViaMenuDropdown.propTypes = {
  isOpen: PropTypes.bool,
  onClickOutside: PropTypes.func,
};

ViaMenuDropdown.defaultProps = {
  isOpen: false,
  onClickOutside: () => {},
};
