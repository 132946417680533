import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ProfileFormGrid from '../../base/ProfileFormGrid.styled';
import ProfileTextInput from '../../base/inputs/ProfileTextInput';
import ProfileSelectInput from '../../base/inputs/ProfileSelectInput';

import honorsStatusOptions from '../../../utils/options/honorsStatusOptions';
import ScreenReaderText from '../../base/ScreenReaderText.styled';

export default function AdditionalAcademicInfoForm({ form, setForm }) {
  const { info, readonly } = useSelector(state => state.travelerProfile);
  const [honorStatusOptionList, setHonorStatusOptionList] = useState([...honorsStatusOptions]);
  const [gpaErrorMessage, setGpaErrorMessage] = useState('');
  useEffect(() => {
    let newHonorStatusOptionList = [...honorStatusOptionList];

    if (info.honors_status) {
      let honorStatus = honorStatusOptionList.find(
        option => option.value === info.honors_status || option.label === info.honors_status,
      );

      if (!honorStatus) {
        newHonorStatusOptionList = [
          ...newHonorStatusOptionList,
          {
            id: Date.now(),
            label: info.honors_status,
            value: info.honors_status,
          },
        ];

        setHonorStatusOptionList([...newHonorStatusOptionList]);
      }
    }

    setForm({
      third_minor: info.third_minor,
      major_gpa: info.major_gpa || '',
      primary_major: info.primary_major,
      primary_minor: info.primary_minor,
      judicial_status: info.judicial_status,
      secondary_major: info.secondary_major,
      secondary_minor: info.secondary_minor,
      honors_status: newHonorStatusOptionList.find(option => option.value === info.honors_status) || '',
    });
  }, [info]);

  return (
    <ProfileFormGrid>
      <ProfileTextInput
        id="primary_major"
        label="Major - Primary"
        placeholder="Enter primary major"
        value={form.primary_major}
        synced={readonly.includes('primary_major')}
        handleOnChange={event => {
          setForm({ ...form, primary_major: event.target.value });
        }}
      />
      <ProfileTextInput
        id="secondary_major"
        label="Major - Secondary"
        placeholder="Enter secondary major"
        value={form.secondary_major}
        synced={readonly.includes('secondary_major')}
        handleOnChange={event => {
          setForm({ ...form, secondary_major: event.target.value });
        }}
      />
      <ProfileTextInput
        id="major_gpa"
        // type="number"
        label="GPA - Major"
        placeholder="Enter major GPA"
        value={form.major_gpa}
        synced={readonly.includes('major_gpa')}
        handleOnChange={event => {
          const value = event.target.value;
          if (/^\d*\.?\d*$/.test(value)) {
            setForm({ ...form, major_gpa: event.target.value });
            setGpaErrorMessage('');
          }
          else {
            setGpaErrorMessage("");
            setTimeout(()=>{
              setGpaErrorMessage('Only numbers are allowed');
            },100)
          }
        }}
      />
      <ScreenReaderText 
        style={{
          visibility: gpaErrorMessage ? 'visible' : 'hidden'
        }}
        role="alert" aria-live="assertive">
        {gpaErrorMessage}
      </ScreenReaderText>
      <ProfileTextInput
        id="primary_minor"
        label="Minor - Primary"
        placeholder="Enter primary minor"
        value={form.primary_minor}
        synced={readonly.includes('primary_minor')}
        handleOnChange={event => {
          setForm({ ...form, primary_minor: event.target.value });
        }}
      />
      <ProfileTextInput
        id="secondary_minor"
        label="Minor - Secondary"
        placeholder="Enter secondary minor"
        value={form.secondary_minor}
        synced={readonly.includes('secondary_minor')}
        handleOnChange={event => {
          setForm({ ...form, secondary_minor: event.target.value });
        }}
      />
      <ProfileTextInput
        id="third_minor"
        label="Minor - Third"
        placeholder="Enter third minor"
        value={form.third_minor}
        synced={readonly.includes('third_minor')}
        handleOnChange={event => {
          setForm({ ...form, third_minor: event.target.value });
        }}
      />
      <ProfileSelectInput
        id="honors_status"
        label="Honors Status"
        placeholder="Select honors status"
        value={form.honors_status}
        options={honorStatusOptionList}
        synced={readonly.includes('honors_status')}
        handleOnChange={event => {
          setForm({ ...form, honors_status: event });
        }}
      />
      <ProfileTextInput
        id="judicial_status"
        label="Judicial Status"
        placeholder="Enter judicial status"
        value={form.judicial_status}
        synced={readonly.includes('judicial_status')}
        handleOnChange={event => {
          setForm({ ...form, judicial_status: event.target.value });
        }}
      />
    </ProfileFormGrid>
  );
}

AdditionalAcademicInfoForm.propTypes = {
  form: PropTypes.shape({
    major_gpa: PropTypes.string,
    third_minor: PropTypes.string,
    primary_major: PropTypes.string,
    primary_minor: PropTypes.string,
    judicial_status: PropTypes.string,
    secondary_major: PropTypes.string,
    secondary_minor: PropTypes.string,
    honors_status: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  setForm: PropTypes.func.isRequired,
};
