import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReportFilters } from '../../../../actions/reportsActions';
import _orderBy from 'lodash/orderBy';
import _cloneDeep from 'lodash/cloneDeep';
import { saveViewSelectValue, restoreViewSelectValue } from '../customStateManagement';
import SaveFilterModal from './saveFilterModal';
import views from '../../../reports/components/pages/forms-v2/savedViews';
import userId from '../../../../shared/helpers/userId';
import StyledIcon from '../../../../styledComponents/styles/Icon.styled';
import customPersonIcon from '../../../../shared/images/custom-icons-person-circle.png';
import customPeopleIcon from '../../../../shared/images/custom-icons-people-circle.png';
import sCustomPageStyles from '../styles/customPageStyles.scss';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  borderBottom: '1px solid #ebebeb',
  textTransform: 'none',
};

const groupBadgeStyles = {
  color: '#767676',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  textAlign: 'center',
  paddingRight: '8px',
};

const getTabStyle = isActive => ({
  padding: '8px 12px',
  cursor: 'pointer',
  background: isActive && 'lightblue',
});

const SelectFilter = props => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.reports.fetchReportFilters);
  const [savedFilterOptions, setSavedFilterOptions] = useState([]);
  const [allViewOptions, setAllViewOptions] = useState([]);
  const [selectedStateGroup, setSelectedStateGroup] = useState('all');
  const [showSaveViewModal, setShowSaveViewModal] = useState(false);
  const [selectFilterView, setSelectFilterView] = useState([]);
  const [viewSelection, setViewSelection] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [filterID, setFilterID] = useState(null);
  const [isSaveView, setIsSaveView] = useState(false);
  const [filterAttributes, setFilterAttributes] = useState(null);

  useEffect(() => {
    if (props.filterKey?.length > 0) {
      dispatch(fetchReportFilters(props.filterKey));
    }
  }, []);

  useEffect(() => {
    let mySortedFilters = [];
    let teamFilters = [];
    let allViews = [];

    if (filters?.data) {
      const sortedList = _orderBy(filters.data, item => item.attributes.name, ['asc']);
      const viewParams = _cloneDeep(views.views);

      sortedList.forEach(item => {
        viewParams[item.id] = JSON.parse(item.attributes.filter);

        if (parseInt(userId) === item.attributes.user_id) {
          mySortedFilters.push({ label: `${item.attributes.name}`, value: item.id });
          allViews.push({
            label: `${item.attributes.name}`,
            value: item.id,
            shared: `${item.attributes.shared}`,
            readOnly: false,
          });
        }

        if (parseInt(userId) !== item.attributes.user_id) {
          teamFilters.push({ label: `${item.attributes.name}`, value: item.id });
          allViews.push({
            label: `${item.attributes.name}`,
            value: item.id,
            shared: `${item.attributes.shared}`,
            readOnly: true,
          });
        }
      });

      const groupSavedOptions = [
        {
          icon: <img src={customPersonIcon} alt={'Created by Me'} width={'25px'} />,
          label: 'Created by Me',
          options: mySortedFilters.length < 1 ? [{ label: 'no views found', value: null }] : mySortedFilters,
        },
        {
          icon: <img src={customPeopleIcon} alt={'Created by Others'} width={'25px'} />,
          label: 'Created by Others',
          options: teamFilters.length < 1 ? [{ label: 'no views found', value: null }] : teamFilters,
        },
      ];

      const combinedFilters = groupSavedOptions;

      setAllViewOptions(allViews);
      setSavedFilterOptions(combinedFilters);
      setSelectFilterView(viewParams);

      const storageSavedView = restoreViewSelectValue(`${props.filterKey}-VIEWID`);
      if (storageSavedView) {
        const findView = allViews.find(v => v.value == storageSavedView);
        if (findView) {
          setFilterID(storageSavedView);
          setFilterAttributes(findView);
        } else {
          setFilterID(null);
          setFilterAttributes(null);
        }
        setViewSelection(findView);
      }
    }
  }, [filters]);

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span style={groupBadgeStyles}>{data.icon}</span>
      <span>{data.label}</span>
    </div>
  );

  const applyFilter = value => {
    if (value) {
      const findView = allViewOptions.find(v => v.value == value);
      if (findView) {
        setFilterID(value);
        setFilterAttributes(findView);
      } else {
        setFilterID(null);
        setFilterAttributes(null);
      }
      const filter = selectFilterView[value];
      setViewSelection(findView);

      if (props.gridRef?.current?.api) {
        if (filter) {
          props.gridRef.current.columnApi.setColumnGroupState(filter?.savedColumnGroupState);
          props.gridRef.current.api.setFilterModel(filter?.savedFilterState);
          props.gridRef.current.columnApi.setPivotMode(filter?.isPivotMode);
          props.gridRef.current.columnApi.applyColumnState({
            state: filter?.savedColumnState,
            applyOrder: true,
          });
          props.gridRef.current.api.setQuickFilter(filter?.savedQuickFilter);
        }
      }
      saveViewSelectValue(value, props.filterKey);
    }
  };

  const resetView = () => {
    setViewSelection(null);
    setFilterID(null);
    setMenuOpen(false);
    localStorage.removeItem(props.filterKey);
    localStorage.removeItem(`${props.filterKey}-VIEWID`);

    setTimeout(() => {
      props.onResetView();
    }, 300);
  };

  const Menu = ({ children, ...props }) => {
    return (
      <components.Menu {...props}>
        <div
          style={{
            display: 'flex',
            padding: '0 0 0 10px',
            borderBottom: '1px solid black',
          }}
        >
          <div onClick={e => setSelectedStateGroup('all')} style={getTabStyle(selectedStateGroup === 'all')}>
            All
          </div>
          <div
            onClick={e => setSelectedStateGroup('created by me')}
            style={getTabStyle(selectedStateGroup === 'createed by me')}
          >
            <img src={customPersonIcon} alt={'Created by Me'} width={'25px'} />
          </div>
          <div
            onClick={e => setSelectedStateGroup('created by others')}
            style={getTabStyle(selectedStateGroup === 'created by others')}
          >
            <img src={customPeopleIcon} alt={'Created by Others'} width={'25px'} />
          </div>
          <div style={{ marginLeft: '45px', alignContent: 'space-around' }} onClick={resetView}>
            <StyledIcon type="Reply" size="16px" />
            <span
              style={{
                marginLeft: '5px',
                fontFamily: 'Nunito',
                fontWeight: '400',
                fontSize: '16px',
                cursor: 'pointer',
              }}
            >
              reset view
            </span>
          </div>
        </div>
        {children}
      </components.Menu>
    );
  };

  const MenuList = ({ children, ...props }) => {
    const filteredChildren = React.Children.toArray(children).filter((group, index) => {
      if (
        selectedStateGroup === 'all' ||
        group.type.name === 'NoOptionsMessage' ||
        (group.props.data && group.props.data.label.toLowerCase() === selectedStateGroup)
      )
        return true;
      return false;
    });

    return <components.MenuList {...props}>{filteredChildren}</components.MenuList>;
  };

  return (
    <div
      style={{
        flex: '1',
        justifyContent: 'left',
        display: 'flex',
      }}
    >
      <div style={{ width: '325px', alignContent: 'left' }}>
        <div className={sCustomPageStyles['filterListLabelWrapper']}>
          <div className={sCustomPageStyles['filterListLabel']}>Saved Views</div>
          <div style={{ alignSelf: 'center' }}>
            {viewSelection && !viewSelection.readOnly && (
              <button
                onClick={() => {
                  setShowSaveViewModal(true);
                }}
                className={sCustomPageStyles['modifyLabel']}
              >
                <StyledIcon type="Pencil" size="16px" />
                <span style={{ marginLeft: '5px' }}>edit view</span>
              </button>
            )}
          </div>
        </div>

        <Select
          name="filterOptions"
          id="filterOptions"
          placeholder="Select a saved view..."
          noOptionsMessage={() => 'No views found'}
          labelText=""
          options={savedFilterOptions}
          menuIsOpen={menuOpen}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          styles={{
            control: provided => ({ ...provided, minHeight: '40px' }),
            menu: provided => ({ ...provided, zIndex: 99999 }),
            placeholder: provided => ({ ...provided, color: '#767676', fontFamily: 'Open Sans', fontSize: '14px' }),
          }}
          formatGroupLabel={formatGroupLabel}
          components={{
            Menu: Menu,
            MenuList: MenuList,
          }}
          onChange={e => {
            applyFilter(e.value);
          }}
          isOptionDisabled={option => option.value === null}
          value={viewSelection}
          // menuIsOpen={true}
        />
      </div>
      <div style={{ alignSelf: 'center' }}>
        <button
          onClick={() => {
            setIsSaveView(true);
            setShowSaveViewModal(true);
          }}
          className={sCustomPageStyles['saveView']}
        >
          <StyledIcon type="Plus" size="16px" />
          <span style={{ marginLeft: '5px' }}>save view</span>
        </button>

        {showSaveViewModal && (
          <SaveFilterModal
            showing={showSaveViewModal}
            filterKey={props.filterKey}
            gridRef={props.gridRef}
            filterID={!isSaveView ? filterID : null}
            filterAttributes={!isSaveView ? filterAttributes : null}
            handlesubmit={view => {
              setTimeout(() => {
                if (view?.value) {
                  setViewSelection(view);
                  setFilterID(parseInt(view.value));
                  setFilterAttributes(view);
                } else {
                  setViewSelection(null);
                  setFilterID(null);
                  setFilterAttributes(null);
                }

                setShowSaveViewModal(false);
                setIsSaveView(false);
              }, 500);
            }}
            onClose={() => {
              setShowSaveViewModal(false);
              setIsSaveView(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SelectFilter;
