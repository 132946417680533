import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'recompose';
import styles from './index.css';
import { Button, Card } from 'react-bootstrap';
import { ThemeProvider } from 'styled-components';
import { themeViaForms } from '../../../../../../styledComponents/settings/theme';
import RegisterEventFormMobile from '../../../registerEventFormMobile';
import { inviteSingleTraveler } from '../../../../../../actions/visitorEventsActions';
import { useDispatch, useSelector } from 'react-redux';
import thumbsUp from '../image/thumbsup.png';
import Facebook from '../../../../../programs/components/header/share/Facebook';
import WhatsApp from '../../../../../programs/components/header/share/WhatsApp';
import CopyLink from '../../../../../programs/components/header/share/CopyLink';
import twitter from '../social/twitter.png';
import location from '../image/location.svg';
import vector from '../image/calendar.svg';
import { stripHtml } from '../../../../../../helpers/CommonHelper';

export const enhance = compose(withStyles(styles));

function EventMobileView({
  event,
  eventDefaultImage,
  handleShareLinkClick,
  shareLink,
  sameDay = false,
  onSubmitEvent,
  onSubmitStart,
  orgLogo
}) {
  const dispatch = useDispatch();
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const inviteTraveler = useSelector(state => state.visitorEvents.inviteSingleTraveler);

  useEffect(() => {
    if (sameDay) {
      setShowRegistrationForm(true);
    }
  }, [sameDay]);

  useEffect(() => {
    if (inviteTraveler && inviteTraveler.status === 'ok') {
      if (!sameDay) {
        setSubmitted(true);
        setShowRegistrationForm(false);
      } else {
        onSubmitEvent?.();
      }
    }
  }, [inviteTraveler]);

  const onSubmit = user => {
    dispatch(
      inviteSingleTraveler(event?.id, {
        first_name: user.firstname,
        last_name: user.lastname,
        email: user.email,
      }),
    );

    if (sameDay) {
      onSubmitStart?.(user);
    }
  };

  const toggleDescription = () => {
    setShowFullDescription(prev => !prev);
  };

  // const renderDescription = () => {
  //   const words = event?.description.split(' ');
  //   if (words.length <= 50 || showFullDescription) {
  //     return event?.description;
  //   }
  //   return `${words.slice(0, 50).join(' ')}...`;
  // };

  const renderDescription = () => {
    let words = stripHtml(event.description); 
    // words = words.split(' ');
    
    if (words.length <= 200 || showFullDescription) {
      return event?.description;
    }
    return `${event?.description?.slice(0, 200)}<span>...</span>`; 
    // return `${event?.description.split(' ').slice(0, 50).join(' ')}...`;
  };

  return (
    <ThemeProvider theme={themeViaForms}>
      <div style={{ padding: '10px' }}>
        <div className="card" style={{ borderRadius: '0.50rem', boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)' }}>
          <div
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}
          >
          <img
            class="card-img-top"
            src={event?.backgroundPhoto ? event.backgroundPhoto : eventDefaultImage}
            alt="Card image cap"
            style={{
              borderTopLeftRadius: '0.50rem',
              borderTopRightRadius: '0.50rem',
              height: '200px',
              // objectFit: 'cover',
              objectPosition: 'center',
            }}
          />

          <div className={`${styles.img_group} text-center`}>
            <Card.Img
              variant="top"
              src={orgLogo}
              className={`img-fluid ${styles.centered_image}`}
              alt="Event host image for the logo"
            />
          </div>
          </div>

          <div
            className="card-body"
            style={{
              overflowY: 'auto',
              maxHeight: '48vh',
            }}
          >
            {!submitted && !sameDay && showRegistrationForm && (
              <div>
                <button className={styles.goBackBtn} type="button" onClick={() => setShowRegistrationForm(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.6249 2.62606C8.85647 2.85763 8.85647 3.23308 8.6249 3.46465L5.48637 6.60318L8.6249 9.74171C8.85647 9.97328 8.85647 10.3487 8.6249 10.5803C8.39333 10.8119 8.01788 10.8119 7.78631 10.5803L4.22849 7.02247C3.99692 6.7909 3.99692 6.41546 4.22849 6.18389L7.78631 2.62606C8.01788 2.39449 8.39333 2.39449 8.6249 2.62606Z"
                      fill="#373737"
                    />
                  </svg>
                  Go Back
                </button>
              </div>
            )}

            <h1 className={styles.eventRegisterHeading}>{sameDay ? "SAME DAY ATTENDANCE" : "ADVANCE SIGN-UP"}</h1>
            <h2 className={styles.eventTitle}>{event?.title}</h2>

            {!submitted ? (
              <>
                {!showRegistrationForm ? (
                  <>
                    <hr />
                    <div className={styles.eventInfo}>
                      <div className={styles.eventInfoLabel}>Location</div>
                      <div className={styles.eventInfoDescription}>
                        <img src={location} />
                        {event?.location}
                      </div>
                    </div>

                    <div className={styles.eventInfo}>
                      <div className={styles.eventInfoLabel}>Date</div>
                      <div className={styles.eventInfoDescription}>
                        <img src={vector} style={{ width: '15px', height: '25px' }} /> {event?.start}{' '}
                        <span className={styles.toText}> to </span>
                        {event?.end}
                      </div>
                    </div>

                    <hr />

                    <div className={styles.eventInfo}>
                      <div className={styles.eventInfoLabel}>Event Description</div>
                      <div className={styles.eventDescription}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: renderDescription(),
                          }}
                        />
                        {stripHtml(event.description)?.length > 200 && (
                          <span className={`${styles.more_detail}`} onClick={toggleDescription}>
                            {showFullDescription ? ' show less' : ' more details'}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.registerFormText}>Enter your information below to confirm attendance.</div>
                    <hr />
                    <RegisterEventFormMobile eventId={event?.id} onSubmit={onSubmit} event={event} sameDay={sameDay} />
                  </>
                )}
              </>
            ) : (
              <>
                <hr />

                <div className={styles.successMsgSection}>
                  <img src={thumbsUp} />
                  <div className={styles.successText}>
                    You’re registered for this event. We’ve sent you an email with event details.
                  </div>

                  <div className={styles.shareEventSection}>
                    <div className={styles.shareEventText}>Share Event:</div>
                    <div>
                      <Facebook url={window.location.href} hashtag="#ViaTRM" clickHandler={handleShareLinkClick} />
                      <a
                        href={'#'}
                        onClick={e => {
                          e.preventDefault();
                          handleShareLinkClick(shareLink.twitter);
                        }}
                        style={{ marginLeft: '8px', marginRight: '8px' }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img variant="top" src={twitter} className={`${styles.social} img-fluid ms-3`} />
                      </a>

                      <WhatsApp url={window.location.href} title={event?.title} clickHandler={handleShareLinkClick} />
                      <CopyLink url={window.location.href} position="bottom" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {!submitted && (
            <div
              className="card-footer"
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                background: 'transparent',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {showRegistrationForm ? (
                <Button
                  type="button"
                  style={{ width: '200px' }}
                  className={`${styles.Register_Btn}`}
                  onClick={() => {
                    document
                      .getElementById('eventFormMobile')
                      .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                  }}
                >
                  {sameDay ? "Confirm Attendance" : "Confirm Sign-up"}
                </Button>
              ) : (
                <Button
                  type="button"
                  className={`${styles.Register_Btn}`}
                  onClick={() => setShowRegistrationForm(true)}
                >
                  Sign-Up
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default enhance(EventMobileView);
