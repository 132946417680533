import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheckboxInput from '../../base/inputs/CheckboxInput';
import { CheckboxGrid } from '../../base/inputs/InputGrids.styled';
import NoBorderTextInput from '../../base/inputs/NoBorderTextInput.styled';
import ScreenReaderLabel from '../../base/inputs/ScreenReaderLabel.styled';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import { breakpoints } from '../../../utils/breakpoints';
import userId from '../../../../../shared/helpers/userId';
import advisingOptions from '../../../utils/intake/advisingOptions';
import { addIntakeAdvising } from '../../../actions/intakeFormActions';
import ScreenReaderLegend from '../../../../travelerProfile/components/base/ScreenReaderLegend.styled';

const CheckboxGridColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column: span 2 / span 2;
  gap: 0.5rem;

  div,
  input[type='text'] {
    grid-column: span 1 / span 1;
  }

  @media ${breakpoints.tablet} {
    grid-template-rows: repeat(8, minmax(0, 1fr));
    grid-column: span 1 / span 1;

    ${props => {
      if (props.otherSelected) {
        return `
          grid-template-rows: repeat(9, minmax(0, 1fr));
        `;
      }
    }};
  }
`;

export default function IntakeQuestionsAndConcerns() {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);

  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [otherOption, setOtherOption] = useState('');

  const question = {
    overline: 'Questions / Concerns',
    heading: 'Ok, do you have any questions or concerns?',
    subheading: 'Select all that apply.',
    isRequired: true,
  };

  useEffect(
    () => {
      if (intake.advising) {
        const selectedAdvisingOptions = [];

        for (const [key, value] of Object.entries(intake.advising)) {
          if (key === 'other_concern_question_text') {
            setOtherOption(value || '');
          }

          if (value === true || value === 't') {
            selectedAdvisingOptions.push(key);
          }
        }

        setValue(selectedAdvisingOptions);
      }
    },
    [intake],
  );

  const handleOnChange = advisingOption => {
    if (value.includes(advisingOption)) {
      setValue(value.filter(element => element !== advisingOption));

      if (advisingOption === 'other') {
        setOtherOption('');
      }
    } else {
      setValue([...value, advisingOption]);
    }
  };

  const handleNext = () => {
    setError("");
    if (value.length === 0) {
      setTimeout(()=>{
        setError('Required, please select an option.');
      },0) 
      throw new Error();
    }

    if (value.includes('other') && !otherOption) {
      setTimeout(()=>{
        setError('Please enter text for other option or uncheck "Other".');
      },0) 
      throw new Error();
    }

    let options = {};

    advisingOptions.forEach(option => {
      options[option.value] = value.includes(option.value);
    });

    options['other_concern_question_text'] = otherOption;

    dispatch(addIntakeAdvising(userId, options));
  };

  return (
    <TravelerFormQuestionContainer question={question} handleNext={handleNext} error={error}>
      <CheckboxGrid>
        <fieldset>
          <ScreenReaderLegend>Select all questions or concerns that apply</ScreenReaderLegend>
        <CheckboxGridColumn otherSelected={value.includes('other')}>
          {advisingOptions.slice(0, 8).map(advisingOption => (
            <CheckboxInput
              key={`advising-option-${advisingOption.id}`}
              id={`advising_option_${advisingOption.id}`}
              value={advisingOption.value}
              label={advisingOption.label}
              checked={value.includes(advisingOption.value)}
              handleOnChange={() => {
                handleOnChange(advisingOption.value);
              }}
            />
          ))}
        </CheckboxGridColumn>
        </fieldset>
        <fieldset>
          <ScreenReaderLegend>Select all questions or concerns that apply Required</ScreenReaderLegend>
        <CheckboxGridColumn otherSelected={value.includes('other')}>
          {advisingOptions.slice(8).map(advisingOption => (
            <CheckboxInput
              key={`advising-option-${advisingOption.id}`}
              id={`advising_option_${advisingOption.id}`}
              value={advisingOption.value}
              label={advisingOption.label}
              checked={value.includes(advisingOption.value)}
              handleOnChange={() => {
                handleOnChange(advisingOption.value);
              }}
            />
          ))}
          {value.includes('other') && (
            <>
              <ScreenReaderLabel htmlFor="other_option_text">Other Option</ScreenReaderLabel>
              <NoBorderTextInput
                id="other_option_text"
                type="text"
                autoComplete='off'
                placeholder="Enter other option"
                value={otherOption}
                onChange={event => {
                  setOtherOption(event.target.value);
                }}
              />
            </>
          )}
        </CheckboxGridColumn>
        </fieldset>
      </CheckboxGrid>
    </TravelerFormQuestionContainer>
  );
}
