import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FilterSearchInput from '../inputs/FilterSearchInput';
import ProgramFilterDropdown from '../ProgramFilterDropdown';
import FilterCheckboxGrid from '../inputs/FilterCheckboxGrid.styled';
import ProfileCheckboxInput from '../../../base/inputs/ProfileCheckboxInput';

import useTermNameOptions from '../../../../hooks/useTermNameOptions';
import { toggleProgramsFilter } from '../../../../actions/programDashboardActions';

const DropdownContainer = styled.div`
  margin: 0 -1.0625rem;
  padding-top: 1rem;
`;

export default function DesktopTermNameFilter({ handleClose }) {
  const dispatch = useDispatch();
  const { selectedFilters } = useSelector(state => state.programDashboard);

  const [allOptions, termNames, setTermNames] = useTermNameOptions();

  const [value, setValue] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (selectedFilters.termName.length > 0) {
      setValue(selectedFilters.termName);
    }
  }, []);

  const handleSearch = debounce(term => {
    if (term) {
      setTermNames(allOptions.filter(termName => termName.label.toLowerCase().includes(term)));
    } else {
      setTermNames(allOptions);
    }
  }, 500);

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
    let searchKey = event.target.value;
    handleSearch(searchKey.toLowerCase());
    
  };

  const handleOnChange = event => {
    const termName = event.target.value;

    if (value.includes(termName)) {
      setValue(value.filter(element => element !== termName));
    } else {
      setValue([...value, termName]);
    }
  };

  const handleClearAll = () => {
    setValue([]);

    dispatch(toggleProgramsFilter({ name: 'termName', value: [] }));
  };

  const handleFilter = () => {
    dispatch(toggleProgramsFilter({ name: 'termName', value }));

    handleClose();
  };
  
  return (
    <ProgramFilterDropdown handleFilter={handleFilter} handleClearAll={handleClearAll} handleClose={handleClose} readerLabel={'Term name filter dialogue box'}>
      <DropdownContainer>
        <FilterSearchInput
          id="term_names_search"
          value={searchTerm}
          label="Search Term Names"
          placeholder="Search Term Names"
          handleOnChange={handleSearchChange}
        />
        <FilterCheckboxGrid>
          {termNames.map(termName => (
            <ProfileCheckboxInput
              key={`term-name-${termName.value.split(' ').join('_')}`}
              id={`term_name_${termName.value.split(' ').join('_')}`}
              value={termName.value}
              label={termName.label}
              checked={value.includes(termName.value)}
              handleOnChange={handleOnChange}
            />
          ))}
        </FilterCheckboxGrid>
      </DropdownContainer>
    </ProgramFilterDropdown>
  );
}

DesktopTermNameFilter.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
