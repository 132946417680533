import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import DatePicker from '../../../../../styledComponents/styles/DatePicker.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import _get from 'lodash/get';
import Form from 'react-bootstrap/Form';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import moment from 'moment-timezone';
import ScreenReaderLabelStyled from '../../../../travelerForms/components/base/inputs/ScreenReaderLabel.styled';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const StartDateContainer = styled.div`
    //display:flex;
`;
const Datecontainer = styled.div`
  display:flex;

  .form-select-date {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    width: 100px;
    height: 45px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    padding-left:5px;
    border: 1px solid var(--ReactSelectTheme-neutral20, #CCC);
    background: var(--ReactSelectTheme-neutral0, #FFF);
  }

  .select-dropdown {
    width: 100px;
    position: relative;
    height: 45px;
  }

  .separator {
    position: absolute;
    top: 50%;
    right: 31px; /* Adjust as needed */
    transform: translateY(-50%);
    width: 1px;
    height: 70%;
    background-color: #ccc; /* Adjust color as needed */
  }
`;

const openSansFont = css`
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
`;

const Error = styled.div`
  color: ${({ theme }) => theme.textInput.error.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.textInput.error.fontFamily};
  font-size: ${({ theme }) => theme.textInput.error.fontSize};
  font-weight: ${({ theme }) => theme.textInput.error.fontWeight};
  padding-top: 3px;
  vertical-align: middle;
  ${({ openSans }) => (openSans ? openSansFont : null)} svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
`;

export default function QuestionDatePickerDisplayForm({
  question,
  formState,
  errors = {},
  setValue,
  value,
  control,
  defaultValue,
  response,
  isReadonly = false,
  disabled = false,
  isSynced = false,
  ...rest
}) {
  const id = question.question_id.toString();
  const errorMsg = _get(errors[id], 'message') || '';
  const [defaultDate, setDefaultDate] = useState(value ? value : defaultValue.value);
  const passportDate = question?.sourceable_attributes?.name === 'passport_expiration_date';
  const dob = question?.sourceable_attributes?.name === 'dob';
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const [years, setYear] = useState([]);
  const [days, setDays] = useState([]);

  const initialState = { 
    day: null, 
    month:null, 
    year:null,
  }
  const [dateValue, setDateValue] = useState(initialState);

  //To set year dropdown
  useEffect(()=>{
    const date = new Date();
    let year = date.getFullYear();
    const yearArray = [];

    let pastYear = year-80;
    let futureYear = parseInt(year) + 80;
    
    if (passportDate) {
      pastYear = year-20;
      futureYear = parseInt(year) + 20;
    } else if (dob) {
      pastYear = year-80;
      futureYear = parseInt(year);
    }

    for(let i=pastYear; i<=futureYear; i++) {
      yearArray.push(i);
    }
    setYear(yearArray);
  },[])

  useEffect(()=>{
    let daysArray = [];
    let maxDay = 31;
    if(dateValue.month=="02") {
      maxDay = 29;
    } else if(["04","06","09","11"].includes(dateValue.month)) {
      maxDay = 30;
    }

    for(let i=1; i<=maxDay; i++) {
      let day = i <= 9 ? `0${i}` : i;
      daysArray.push(day);
    }
    setDays(daysArray);
  },[dateValue.month])

  const monthsArray = [
    { label: 'Jan', value: "01",  ariaLabel:"January"},
    { label: 'Feb', value: "02" , ariaLabel:"February"},
    { label: 'Mar', value: "03" , ariaLabel:"March"},
    { label: 'Apr', value: "04" , ariaLabel:"April"},
    { label: 'May', value: "05" , ariaLabel:"May"},
    { label: 'Jun', value: "06" , ariaLabel:"June"},
    { label: 'Jul', value: "07" , ariaLabel:"July"},
    { label: 'Aug', value: "08" , ariaLabel:"August"},
    { label: 'Sep', value: "09" , ariaLabel:"September"},
    { label: 'Oct', value: "10" , ariaLabel:"October"},
    { label: 'Nov', value: "11" , ariaLabel:"November"},
    { label: 'Dec', value: "12" , ariaLabel:"December"}
  ];

  //To set form date value
  useEffect(()=>{
    let date = "";
   
    if(dateValue.day && dateValue.month && dateValue.year){
      date = `${dateValue.year}-${dateValue.month}-${dateValue.day}`;
    }
    const skipValidate = !date || defaultDate == date;

    setValue(id, date, {
      shouldValidate: !!defaultDate || skipValidate ? false : true,
      shouldTouch: !skipValidate,
      shouldDirty: !skipValidate,
    });
  },[dateValue]);

  const handleOnChange = (event) => {
    setDateValue({ ...dateValue, [event.target.name] : event.target.value });
  };

  useEffect(() => {
    if (defaultValue) {
      setDefaultDate(defaultValue.value);
    }

    const date = moment(value);
    const year = date.format("YYYY");
    const month = date.format("MM");
    const day = date.format("DD");

    setDateValue({ 
      day: day, 
      month:month, 
      year:year,
    })

    if (dob) {
      setMinDate(new Date(new Date().getFullYear() - 100, 0, 1).toString());
    }

    if (passportDate) {
      setMinDate(new Date(new Date().getFullYear() - 20, 0, 1).toString());
      setMaxDate(new Date(new Date().getFullYear() + 20, 0, 1).toString());
    }
  }, []);
  const updatedIntro = !!question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : '';
  return (
    <Container>
      <InputContainer>
        <Label 
            id={`label-${id}`}
            tabIndex={0} 
            // aria-label="Datepicker"
          >
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          role="contentinfo"
          aria-label={question.label}
          id={`date-picker-instructions-${question?.label?.toLowerCase().replaceAll(' ', '_')}`}
          tabIndex={1}
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedIntro,
          }}
        />
        {/* {!isTravelerProfile ?  */}
        {/* <DatePicker
          disablePast={!dob && !passportDate && question.future_only}
          disableFuture={dob}
          id={id}
          defaultValue={defaultDate}
          setValue={e => {
            const skipValidate = !e || defaultDate == e;

            setValue(id, e, {
              shouldValidate: !!defaultDate || skipValidate ? false : true,
              shouldTouch: !skipValidate,
              shouldDirty: !skipValidate,
            });
          }}
          errorMsg={errorMsg}
          disabled={isReadonly}
          isReadonly={isReadonly}
          isSynced={isSynced}
          minDate={minDate}
          maxDate={maxDate}
          {...rest}
        /> */}

        <StartDateContainer>
          <Datecontainer>
            <div className="select-dropdown">
              <ScreenReaderLabelStyled htmlFor={`month-${id}`} id={`label-month-${id}`} aria-label='Datepicker Month'>Select Month</ScreenReaderLabelStyled>
              <Form.Select
                className="form-select-date"
                onChange={handleOnChange}
                id={`month-${id}`}
                aria-label="Select Month"
                name="month"
                value={dateValue.month}
                aria-labelledby={`label-month-${id}`}
                // aria-labelledby={`label-${id}`}
                // aria-describedby={`message-${question?.label?.toLowerCase().replaceAll(' ', '_')}`}
              >
                <option value="" label="MM">
                  MM
                </option>
                {monthsArray.map(month => {
                  return (
                    <option key={`start-${month.value}`} value={month.value} label={month.ariaLabel}>
                      {month.label}
                    </option>
                  );
                })}
              </Form.Select>
              <div className="separator"></div>
            </div>
            <div className="select-dropdown">
              <ScreenReaderLabelStyled htmlFor={`day-${id}`} id={`label-date-${id}`} aria-label='Datepicker Date'>Select Date</ScreenReaderLabelStyled>
              <Form.Select
                value={dateValue.day}
                style={{ marginLeft: '20px' }}
                className="form-select-date"
                aria-label="Select Date"
                onChange={handleOnChange}
                id={`day-${id}`}
                name="day"
                aria-labelledby={`label-date-${id}`}
                // aria-labelledby={`label-${id}`}
                // aria-describedby={`message-${question?.label?.toLowerCase().replaceAll(' ', '_')}`}
              >
                <option value="" label="Select Date">
                  DD
                </option>
                {days.map(day => {
                  return (
                    <option key={`start-${day}`} value={day}>
                      {day}
                    </option>
                  );
                })}
              </Form.Select>
              <div className="separator" style={{ right: '12px' }}></div>
            </div>
            <div className="select-dropdown" style={{ marginLeft: '20px' }}>
              <ScreenReaderLabelStyled htmlFor={`year-${id}`} id={`label-year-${id}`} aria-label='Datepicker Year'>Select Year</ScreenReaderLabelStyled>
              <Form.Select
                id={`year-${id}`}
                value={dateValue.year}
                style={{ marginLeft: '20px' }}
                className="form-select-date"
                aria-label="Select Year"
                onChange={handleOnChange}
                name="year"
                aria-labelledby={`label-year-${id}`}
                // aria-labelledby={`label-${id}`}
                // aria-describedby={`message-${question?.label?.toLowerCase().replaceAll(' ', '_')}`}
              >
                <option value="" label="Select Year">
                  YYYY
                </option>
                {years.map(year => {
                  return (
                    <option key={`start-${year}`} value={year}>
                      {year}
                    </option>
                  );
                })}
              </Form.Select>
              <div className="separator" style={{ right: '12px' }}></div>
            </div>
          </Datecontainer>

          {/* <div id={`message-${question?.label?.toLowerCase().replaceAll(' ', '_')}`}> */}
            {errorMsg && errorMsg.length > 0 && (
              <Error openSans={openSansFont} aria-live="polite">
                <StyledIcon type={'Warning'} size={'16px'} />
                <span>{errorMsg}</span>
              </Error>
            )}
          {/* </div> */}
        </StartDateContainer>
        {/* } */}
      </InputContainer>
    </Container>
  );
}