import React, { useEffect, useRef, useState } from 'react';
import sImageViewer from './ImageViewer.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
// import CloseButton from '../CloseButton/CloseButton.js';
// import CloseIcon from '../../sites/travelerProfile/components/icons/CloseIcon.js';
import ScreenReaderHeadingComponent from '../../sites/travelerProfile/components/heading/ScreenReaderHeadingComponent/index.js';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import closeIcon from '../../shared/images/close.png';

function ImageViewer(props) {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    document.getElementById("preview-close-button").setAttribute("tabindex", "0");
    document.getElementById("preview-close-button").focus(); 
  }, []); 

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleClose();
      }}
    >
      <Modal.Header className={sImageViewer.modelHeader}>
        <div>
          <Modal.Title>Preview</Modal.Title>
          <ScreenReaderHeadingComponent text="Preview" />
        </div>

        <div>
          <button
            id="preview-close-button"
            type="button"
            tabIndex={0}
            className={sImageViewer.modelCloseBtn}
            onClick={() => {
              props.handleClose();
            }}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </div>
      </Modal.Header>

      <Modal.Body>
        {!isError && (
          <div className={sImageViewer['react-pdf']}>
            <img src={props.file} className={sImageViewer.previewImg} onError={e => setIsError(true)} />
          </div>
        )}
        {isError && <div className={sImageViewer['react-pdf']}>Unable to load image</div>}
      </Modal.Body>

      {/* <div id={background}> */}
      {/* <div id={showHideClassName} className={sImageViewer.modal}> */}
      {/* <div className={sImageViewer.header} ref={headerRef}>
          <p>Preview</p>
          
          <button type="button" className={sImageViewer.closeButton} 
            onClick={()=>{
                props.handleClose();
                setShow(false);
              }
            }
            aria-label="Close"
          >
            <CloseIcon fillColor={`#9FABAE`} />
          </button>
        </div> */}

      {/* </div> */}
      {/* </div> */}
    </Modal>
  );
}

export default withStyles(sImageViewer)(ImageViewer);
