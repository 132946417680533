import React from 'react';
import customPersonIcon from '../../../../shared/images/custom-icons-person-circle.png';
import customPeopleIcon from '../../../../shared/images/custom-icons-people-circle.png';
import { Spinner } from '../../library/spinner';
import { defaultUserProfileAvatar } from '../../../../shared/helpers/plans';

const CustomLoadingOverlay = props => {
  return (
    <div
      className="ag-overlay-loading-center"
      role="presentation"
      style={{ padding: '20px', fontFamily: 'Nunito', fontSize: '16px' }}
    >
      <div aria-live="polite" aria-atomic="true" style={{ paddingBottom: '20px' }}>
        {props.loadingMessage}
      </div>
      <div>
        <Spinner as="span" animation="border" size="dataLoading" kind="bar" role="status" aria-hidden="true" />
      </div>
    </div>
  );
};

const CustomNoRowsOverlay = props => {
  return (
    <div
      className="ag-overlay-loading-center"
      role="presentation"
      style={{ padding: '20px', fontFamily: 'Nunito', fontSize: '16px' }}
    >
      <div aria-live="polite" aria-atomic="true">
        {props.loadingMessage}
      </div>
    </div>
  );
};

const customLowerCaseComparator = (valueA, valueB) => {
  if (typeof valueA === 'string') {
    return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
  }

  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

const customNumericComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  return valueA - valueB;
};

const customDateComparator = (filterLocalDateAtMidnight, cellValue) => {
  let cellDate = cellValue;
  let dateParts = [];
  const timestampDate = cellValue.toLowerCase().includes('t');
  const dateAsString = cellValue;

  if (dateAsString == null) return -1;

  if (cellValue.includes(',')) {
    //Jan 01, 2021

    const d = new Date(cellValue);
    let month = d.getMonth() + 1;
    let day = d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    const rebuildDate = [year, month, day];

    dateParts = rebuildDate;
  } else if (timestampDate) {
    // 2024-07-02T13:57:52.025-06:00 example

    const dateOnly = dateAsString.split('T')[0];
    dateParts = dateOnly.split('-');
  } else {
    // 2024-07-02

    dateParts = dateAsString.split('-');
  }

  cellDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));

  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }

  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

const travelTypeIcon = params => {
  const iconName = params.data.type.toLowerCase();

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      {iconName === 'solo' ? (
        <img src={customPersonIcon} alt={'Person Icon'} width={'30px'} />
      ) : (
        <img src={customPeopleIcon} alt={'People Icon'} width={'30px'} />
      )}
    </div>
  );
};

const travelerAvatar = params => {
  const avatar = params.data.avatar_url.toLowerCase();

  return (
    <div
      style={{
        position: 'relative',
        width: '35px',
        height: '35px',
        overflow: 'hidden',
        borderRadius: '50%',
        marginLeft: '10px',
      }}
    >
      <img
        src={avatar}
        alt="Avatar"
        style={{ width: '100%', height: 'auto' }}
        onError={e => {
          e.currentTarget.src = defaultUserProfileAvatar();
        }}
      />
    </div>
  );
};

const defaultColDefaults = () => {
  return {
    minWidth: 5,
    width: 175,
    resizable: true,
    enableValue: true,
    enableRowGroup: false,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortable: true,
    enablePivot: true,
    suppressHorizontalScroll: false,
    comparator: customLowerCaseComparator,
    newRowsAction: 'keep',
    suppressRowClickSelection: true,
    sortingOrder: ['asc', 'desc'],
  };
};

const defaultSidebarDefaults = () => {
  return {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Add Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 360,
        maxWidth: 360,
        width: 360,
        toolPanelParams: {
          suppressRowGroups: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressValues: true,
        },
      },
      {
        id: 'filters',
        labelDefault: 'Custom Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        minWidth: 180,
        maxWidth: 400,
        width: 250,
        toolPanelParams: {
          suppressRowGroups: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressValues: true,
        },
      },
    ],
    position: 'right',
  };
};

const defaultStatusBarDefaults = () => {
  return {
    statusPanels: [
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
      { statusPanel: 'agTotalRowCountComponent', align: 'center' },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
      // { statusPanel: 'agAggregationComponent' },
    ],
  };
};

const customContextMenuItems = params => {
  const hideContext = params.column.userProvidedColDef.hideContextMenu;
  let menuItems = [];

  if (!hideContext) {
    menuItems = ['copy', 'copyWithHeaders', 'copyWithGroupHeaders', 'separator', 'export'];
  }
  return menuItems;
};

export {
  CustomLoadingOverlay,
  CustomNoRowsOverlay,
  defaultColDefaults,
  defaultSidebarDefaults,
  defaultStatusBarDefaults,
  travelTypeIcon,
  customNumericComparator,
  customDateComparator,
  travelerAvatar,
  customContextMenuItems,
};
