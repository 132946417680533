import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import BackLink from '../navigation/BackLink';
import SkipLink from '../navigation/SkipLink';
import VisitorLink from '../navigation/VisitorLink';
import MenuDropdown from '../navigation/MenuDropdown';
import MobileHeader from '../navigation/MobileHeader';
import AccountNavigation from '../navigation/AccountNavigation';
import SidebarNavigation from '../navigation/SidebarNavigation';

import { breakpoints } from '../../utils/breakpoints';

import { MenuIcon} from '../../../../styledComponents/styles/IconCustom.styled';
import ViaMenuDropdown from '../navigation/ViaMenuDropdown';

const Container = styled.div`
  display: block;
  background-color: var(--theme_color_dark);
`;

const DivPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.4375rem;
  min-height: 90vh;
  background-color: var(--theme_color_dark);

  @media ${breakpoints.desktop} {
    flex-direction: row;
    align-items: flex-start;
    padding: 1.5rem 2rem 3.75rem 1.5rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 0.0625rem 3.75rem 0.0625rem;

  @media ${breakpoints.tablet} {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }

  @media ${breakpoints.desktop} {
    padding: 0;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.showSideNav || !props.isTraveler ? 'flex-end' : 'space-between')};
  align-items: center;
`;

const AccountNavContainer = styled.div`
  display: none;
  
  @media ${breakpoints.desktop} {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 2rem 0 1.5rem;
  }
`;

const ClearDiv = styled.div`
  clear: both;
`;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: ${props => (props.noPadding ? '0' : '3rem')};

  @media ${breakpoints.tablet} {
    padding: ${props => (props.noPadding ? '0' : '3rem 2.1875rem')};
  }
`;

const MenuContainer = styled.div`
  width:50px;
  height:50px;
  border-radius:50%;
  background:#FFF;
  display:flex;
  justify-content:center;
  text-align:center;
`

const MenuIconButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;

  img {
    position: relative;
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &:focus-visible {
    img {
      outline: 2px solid #255a94;
    }
  }
`;

export default function PageWrapper({
  children,
  routeSegment,
  showSideNav,
  showMenuDropdown,
  noPadding,
  layout,
  legacyWrapper,
}) {
  const { adminAccess, role,inboundOrgData } = useSelector(state => state.travelerProfile);
  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);
  const { clientFeatureList } = useSelector(state => state.profile);
  const [menuIconIsOpen, setMenuIconIsOpen] = useState(false);
  const menuButtonRef = useRef(null);
  
  const isTraveler = role === 'traveler';
  
  return (
    <>
      {layout === 'full' && !legacyWrapper ? (
        <>
          <SkipLink />
          <Container>
            <MobileHeader />
            <AccountNavContainer>
              <div>
                { clientFeatureList?.inbound && 
                  <MenuContainer>
                    <MenuIconButton
                      ref={menuButtonRef}
                      type="button"
                      aria-label={menuIconIsOpen ? 'Close Menu' : 'Open Menu'}
                      onClick={() => {
                        setMenuIconIsOpen(!menuIconIsOpen);
                      }}
                    >
                      <MenuIcon />
                    </MenuIconButton>
                  </MenuContainer>
                }
                {menuIconIsOpen && (
                  <ViaMenuDropdown
                    isOpen={menuIconIsOpen}
                    menuButtonRef={menuButtonRef}
                    inboundOrgData={inboundOrgData}
                    onClickOutside={() => {
                      setMenuIconIsOpen(false);
                    }}
                  />
                )}
             
              </div>
              
              {isTraveler || adminAccess ? <AccountNavigation /> : <VisitorLink />}
            </AccountNavContainer>
            <ClearDiv />
            <DivPageContainer>
              {showSideNav && <SidebarNavigation routeSegment={routeSegment} />}
              <ContentContainer>
                <HeaderContainer showSideNav={showSideNav} isTraveler={isTraveler}>
                  {!showSideNav &&
                    (isTraveler || adminAccess) &&
                    (showMenuDropdown ? (
                      <MenuDropdown />
                    ) : (
                      <BackLink
                        url={`/traveler/my-programs/details/${travelerSubmission?.data?.id}`}
                        text="Back to Program Details"
                      />
                    ))}
                </HeaderContainer>
                <Content id="main" noPadding={noPadding}>
                  {children}
                </Content>
              </ContentContainer>
            </DivPageContainer>
          </Container>
        </>
      ) : (
        <Content id="main" noPadding={noPadding}>
          {children}
        </Content>
      )}
    </>
  );
}

PageWrapper.propTypes = {
  noPadding: PropTypes.bool,
  showSideNav: PropTypes.bool,
  routeSegment: PropTypes.string,
  layout: PropTypes.string,
  legacyWrapper: PropTypes.bool,
  showMenuDropdown: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isVisitor: PropTypes.bool,
};

PageWrapper.defaultProps = {
  noPadding: true,
  routeSegment: '',
  layout: 'full',
  legacyWrapper: false,
  showSideNav: true,
  showMenuDropdown: true,
  isVisitor: false,
};
