import moment from 'moment-timezone';
import React, { useState, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import CalendarIcon from '../icons/CalendarIcon';
// import LocationIcon from '../icons/LocationIcon';
// import SignPostIcon from '../icons/SignPostIcon';
import EmptyHeartIcon from '../icons/EmptyHeartIcon';
import FilledHeartIcon from '../icons/FilledHeartIcon';

import SignIcon from '../../../../shared/images/sidemenuexpprog.png';
import LocationIcon from '../../../../shared/images/sidemenuregtravel.png';

import Calendar from '../../../../shared/images/group-date.png';

import {
  Card,
  ProgramCards,
  CardImage,
  ProgramName,
  Organization,
  ProgramHeader,
  ApplyNowBanner,
  FavoriteButton,
  ProgramDetails,
  MatchPercentage,
  SubjectAreasList,
  ProgramDetailItem,
  AdditionalItemsText,
  ProgramSubjectAreas,
  SubjectAreasHeading,
  ProgramInfoContainer,
  IconImage,
} from './ProgramCard.styled';

import programType from '../../types/program';
import userId from '../../../../shared/helpers/userId';
import { toggleFavorite } from '../../../../actions/programDashboardActions';

export default function ProgramCard({ program, toggleFavoriteProgram }) {
  const dispatch = useDispatch();
  const { role, matchComplete, showMatch } = useSelector(state => state.travelerProfile);
  const programFavoriteDisclaimerAccepted = useSelector(state => state.profile.program_favorite_disclaimer_accepted);
  const favoriteDisclaimerUpdated = useSelector(state => state.programBrochure.update_favorite_disclaimer);
  const { favoriteProgram } = useSelector(state => state.profile);

  const {
    customAliases: { alias_favorite: favoriteAlias = 'Favorite', alias_unfavorite: unfavoriteAlias = 'Unfavorite' },
  } = useSelector(state => state.profile);

  const [isFavorite, setIsFavorite] = useState(false);

  const isTraveler = role === 'traveler';
  const programTypesArray = program.types.split(';');
  const programSubjectAreasArray = [...new Set(program.program_subject_areas.split(';'))];

  const programTermsArray = program.program_terms.split('; ').filter(term => {
    if (term.toLowerCase().includes('application') || term.toLowerCase().includes('deadline')) {
      return false;
    }

    const termNameArray = term.split(':');
    const dateCheck = termNameArray[1] ? termNameArray[1].split('-')[0].trim() : termNameArray[0].split('-')[0].trim();

    return moment(dateCheck).isAfter(moment());
  });

  useEffect(() => {
    setIsFavorite(program?.program_favorite); 
  }, [program]); 

  useEffect(() => {
    if (favoriteProgram?.programId) {
      if (Number(favoriteProgram?.programId) === Number(program?.program_id)) {
        setIsFavorite(favoriteProgram?.flag);
      }
    }
  }, [favoriteProgram?.programId, favoriteProgram?.flag]);

  const handleToggleProgramFavorite = event => {
    event.stopPropagation();
    event.preventDefault();

    if (!isFavorite) {
      if (programFavoriteDisclaimerAccepted || favoriteDisclaimerUpdated) {
        setIsFavorite(!isFavorite);
        const isFavDisAccepted = false;
        dispatch(toggleFavorite(userId, program.program_id, !isFavorite, isFavDisAccepted));
      } else {
        toggleFavoriteProgram(program.program_id, !isFavorite);
      }
    } else {
      setIsFavorite(!isFavorite);
      dispatch(toggleFavorite(userId, program.program_id, !isFavorite));
    }
  };

  const redirectToProgramDetail = (programID) => {
    if(isTraveler) {
      window.location.href = `/program_brochure/${programID}`;
    } else {
      window.open(`/program_brochure/${programID}`, '_blank');
    }
  }


  return (
    <ProgramCards tabIndex={0} 
      role="listitem"
      onClick={()=>redirectToProgramDetail(program.program_id)}  
      onKeyUp={event => {
        if (event.key === 'Enter') {
          redirectToProgramDetail(program.program_id);
        }
      }}
      aria-label={`${program.organization_name} ${program.program_match_percentage}% Match ${program.program_name} location ${program.locations} terms ${programTermsArray.slice(0, 2).join('; ')}  Subject areas${programSubjectAreasArray.join('')}`} 
    >
      <ApplyNowBanner className="apply-now-banner">Apply Now</ApplyNowBanner>
      <CardImage image={program.background_xl}>
        <Organization>{program.organization_name}</Organization>
        {showMatch && matchComplete && (
          <MatchPercentage percentage={program.program_match_percentage}>
            {program.program_match_percentage}% Match
          </MatchPercentage>
        )}
      </CardImage>
      <ProgramInfoContainer>
        <ProgramHeader>
          <ProgramName>{program.program_name}</ProgramName>
          {isTraveler && (
            <FavoriteButton
              type="button"
              aria-label={`${isFavorite ? unfavoriteAlias : favoriteAlias}`}
              onClick={event => {
                handleToggleProgramFavorite(event);
              }}
              onKeyUp={event => {
                if (event.key === 'Enter') {
                  handleToggleProgramFavorite(event);
                }
              }}
            >
              {isFavorite ? (
                <FilledHeartIcon fillColor={document.documentElement.style.getPropertyValue('--theme_color_accent')} />
              ) : (
                <EmptyHeartIcon />
              )}
            </FavoriteButton>
          )}
        </ProgramHeader>
        <ProgramDetails>
          <ProgramDetailItem>
            <IconImage src={LocationIcon} alt="Location" />
            <span>{program.locations}</span>
          </ProgramDetailItem>
          {programTermsArray.length > 0 && (
            <ProgramDetailItem>
              <IconImage src={Calendar} alt="Date" />
              <span>
                <span>{programTermsArray.slice(0, 2).join('; ')}</span>
                {programTermsArray.length > 2 && (
                  <AdditionalItemsText>+{programTermsArray.length - 2} more</AdditionalItemsText>
                )}
              </span>
            </ProgramDetailItem>
          )}
          <ProgramDetailItem>
            <IconImage src={SignIcon} alt="Sign" />
            <span>
              <span>{programTypesArray.slice(0, 2).join('; ')}</span>
              {programTypesArray.length > 2 && (
                <AdditionalItemsText>+{programTypesArray.length - 2} more</AdditionalItemsText>
              )}
            </span>
          </ProgramDetailItem>
        </ProgramDetails>
      </ProgramInfoContainer>
      <ProgramSubjectAreas>
        <SubjectAreasHeading>Subject Areas</SubjectAreasHeading>
        <SubjectAreasList>
          {programSubjectAreasArray.slice(0, 3).map(subjectArea => (
            <li key={subjectArea}>{subjectArea}</li>
          ))}
        </SubjectAreasList>
        {programSubjectAreasArray.length > 2 && (
          <AdditionalItemsText forSubjects>+{programSubjectAreasArray.length - 2} more</AdditionalItemsText>
        )}
      </ProgramSubjectAreas>
    </ProgramCards>
  );
}

ProgramCard.propTypes = {
  program: programType.isRequired,
};
